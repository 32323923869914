import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { showLostConnectionAtom } from '$business/atoms/show-lost-connection';

export function useShowLostConnectionAtom(): {
  showConnectionAlert: boolean;
  closeConnectionAlert: () => void;
  openConnectionAlert: () => void;
} {
  const [showConnectionAlert, setShowConnectionAlert] = useAtom(showLostConnectionAtom);

  const closeConnectionAlert = useCallback(() => {
    setShowConnectionAlert(false);
  }, [setShowConnectionAlert]);

  const openConnectionAlert = useCallback(() => {
    setShowConnectionAlert(true);
  }, [setShowConnectionAlert]);

  return { showConnectionAlert, closeConnectionAlert, openConnectionAlert };
}
