async function n(e) {
  if (e.order.outcome === "ready")
    try {
      const { origin: r } = new URL(e.sourceUrl ?? ""), t = btoa(JSON.stringify({ order: e.order }));
      if (`${r}/pages/just-post-purchase?data=${t}`.length > 2e3) {
        const o = await fetch(process.env.NEXT_PUBLIC_PASSTHROUGH_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ data: t })
        }).then((a) => a.json());
        location.href = `${r}/pages/just-post-purchase?passthrough=${o.createdId}`;
      } else
        location.href = `${r}/pages/just-post-purchase?data=${t}`;
    } catch {
      window.location.replace(e.order.url);
    }
}
function s() {
  return {
    redirectToOrderPage: n
  };
}
function c(e) {
  if (e === "shopify")
    return s();
}
export {
  c as getCMSInterceptors
};
