import { setStorage } from '@getjust/commons';
import { useCallback } from 'react';

import { useCustomRouter } from '$src/hooks/client/useCustomRouter';
import { useShop } from '$src/hooks/queries';
import { useCartIdAtom } from '$src/hooks/state';

export const useCartIdUpdater = () => {
  const { cartId, onCartIdChange } = useCartIdAtom();
  const router = useCustomRouter();
  const { data: shop } = useShop();
  const updateCartId = useCallback(
    (id: string) => {
      if (id && cartId !== id) {
        onCartIdChange(id);
        // send to top frame (iframe case)
        postMessage({
          emitter: 'JUST_FORM',
          action: 'SET_SESSION_ID',
          cartId: id,
        });
        // redirect case
        if (router?.query?.data || router?.query?.passthrough) {
          setStorage<string>('localStorage', `just_session_id_${shop?.id}`, id);
        }
      }
    },
    [cartId, onCartIdChange, router?.query?.data, router?.query?.passthrough, shop?.id],
  );
  return updateCartId;
};
